/* index.css */
.about-us {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.about-us.active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.img-bg {
  background-image: url('../../../public/assets/img/about-us-bg.jpg');
  background-color: rgba(15,23,62,0.6);
  background-size: cover;
  position: relative;
  background-blend-mode: multiply;
}

.about-us {
  padding-bottom: 0;
}

.about-us .img-bg {
  min-height: 500px;
  background-size: cover;
}

.about-us .slides {
  background-color: #fff;
}

.about-us h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-secondary);
}

.about-us h4 {
  font-size: 18px;
  font-weight: 400;
  color: #29486a;
  font-family: var(--font-secondary);
}

.about-us .swiper {
  margin: 140px 140px 120px 140px;
  overflow: hidden;
}

.about-us .swiper-button-prev:after,
.about-us .swiper-button-next:after {
  font-size: 24px;
  color: var(--color-secondary);
}

.about-us .swiper-button-prev {
  left: 80px;
}

.about-us .swiper-button-next {
  right: 80px;
}

.about-us .swiper-pagination {
  margin-top: 30px;
  position: relative;
}

.about-us .swiper-pagination .swiper-pagination-bullet {
  background-color: var(--color-secondary);
}

.about-us .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

@media (max-width: 1200px) {
  .about-us .swiper {
    margin: 60px 60px 40px 60px;
  }

  .about-us .swiper-button-prev,
  .about-us .swiper-button-next {
    display: none;
  }
}

@media (max-width: 575px) {
  .about-us .swiper {
    margin: 40px 40px 20px 40px;
  }
}

.page-about .about-us {
  padding: 0 0 80px 0;
}
